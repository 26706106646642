// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Login from './Login';
import moment from 'moment';
import { SortOrder } from 'react-data-table-component';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   // Make a POST request to fetch data
  //   // axios.post('https://api.truhealthz.com/api/User/GetAllUsers', {})
  //   axios.post('https://api.healthsaxis.com/api/User/GetAllUsers', {})
  //     .then((response) => {
  //       // Log the response to check its structure
  //       console.log(response.data["data"]);
  //       let finalData = response.data["data"];

  //       // Filter out rows where roleId is 1
  //       const filteredData =finalData.filter(user => user.roleId !== 1);
  //       // Set data if the response structure is as expected
  //       if (Array.isArray(filteredData)) {
  //         setData(filteredData);
  //       } else {
  //         setError('Unexpected data format');
  //       }

  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       setError('Error fetching data');
  //       setLoading(false);
  //     });
  // }, []);


  // if (loading) {
  //   return <div className="App">Loading...</div>;
  // }

  // if (error) {
  //   return <div className="App">{error}</div>;
  // }

  const columns = [
    {
      Header: 'User Id',
      accessor: 'userId'
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY')
    },
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure'
    },
    {
      Header: 'Pulse Rate',
      accessor: 'pulseRate'
    },
    {
      Header: 'Breathing Rate',
      accessor: 'respirationRate'
    },
    {
      Header: 'SPo2',
      accessor: 'oxygenSaturation'
    },
    {
      Header: 'Wellness',
      accessor: 'wellnessLevel',
    },
    {
      Header: 'Stress Level',
      accessor: 'stressLevel',
    },
    
  ]

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
