import React, { useState } from "react";
import useForm from "./UseForm";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = props => {

let navigate = useNavigate();

const submit = async () => {
    const toastId = toast.loading("Uploading...");
    try {
      let res = await axios.post('https://api.healthsaxis.com/api/Authentication/Login', {
        email: values.email,
        password: values.password
      });
      const token = res?.data?.data?.token;
      console.log("token",res?.data?.data?.token)
      sessionStorage.setItem("token", token);
      if (res?.data && res?.data?.status) {
        toast.update(toastId, { render: "Successfully Logged In", type: "success", isLoading: false, autoClose: 3000 });
        navigate("/dashboard");
      } else {
        toast.update(toastId, { render: "Wrong Credentials!", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      console.error("Login failed", error);
      toast.update(toastId, { render: "An error occurred!", type: "error", isLoading: false, autoClose: 3000 });
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm();


  return (
    
    <div className="section is-fullheight">
    <ToastContainer />
      <div className="container">
        <div className="column is-6 is-offset-3">
          <div className="box">
            <h1>Login to Healthsaxis</h1>
              <div className="field">
                <label className="label">Email Address</label>
                <div className="control">
                  <input
                    autoComplete="off"
                    // className={`input ${errors.email && "is-danger"}`}
                    className="form-control"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ""}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    // className={`input ${errors.password && "is-danger"}`}
                    className="form-control"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password || ""}
                    required
                  />
                </div>
              </div>
              <button
                // type="submit"
                onClick={submit}
                className="button is-block is-info is-fullwidth"
              >
                Login
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
