import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableComponent from './SimpleTable';
import moment from 'moment';
import Privacy from './Privacy';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    axios.post('https://api.healthsaxis.com/api/User/GetAllUsers', {},{
        headers: {
            'Authorization': `Bearer ${token}`
          }
    })
      .then((response) => {
        console.log(response.data["data"]);
        let finalData = response.data["data"];

        // Filter out rows where roleId is 1
        const filteredData =finalData.filter(user => user.roleId !== 1);
        // Set data if the response structure is as expected
        if (Array.isArray(filteredData)) {
          setData(filteredData);
        } else {
          setError('Unexpected data format');
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <div className="App">Loading...</div>;
  }

  if (error) {
    return <div className="App">{error}</div>;
  }

  const columns = [
    {
      Header: <div>User Id</div>,
      accessor: 'userId',
      Cell: ({ value }) => <div style={{ minWidth: '50px', textAlign: 'center' }}>{value}</div>
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY')
    },
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure'
    },
    {
      Header: 'Pulse Rate',
      accessor: 'pulseRate'
    },
    {
      Header: 'Breathing Rate',
      accessor: 'respirationRate'
    },
    {
      Header: 'SPo2',
      accessor: 'oxygenSaturation'
    },
    {
      Header: 'Wellness',
      accessor: 'wellnessLevel',
    },
    {
      Header: 'Stress Level',
      accessor: 'stressLevel',
    },  
  ]


  return (
    <div>
        <TableComponent columns={columns} data={data} />
    </div>
  )
}

export default Dashboard